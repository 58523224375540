import './App.css';
import { Beer } from './Beer';

function App() {
  return (
    <div className="App">
      <Beer />
      
    </div>
  );
}

export default App;
