import React from 'react';
export const Beer = () => {
    const date = new Date();
    const dayNumber = date.getDay();
    const beerClasses = [''];
    switch (dayNumber) {
        case 1:
            beerClasses.push('monday')
            break;
        case 2:
            beerClasses.push('tuesday')
            break;
        case 3:
            beerClasses.push('wednesday')
            break;
        case 4:
            beerClasses.push('thursday')
            break;
        case 5:
            beerClasses.push('friday')
            break;
        default:
            beerClasses.push('friday')
    }
    return (
        
        <div id='beer-comp'>   
        <div className='pyro' style={{display: dayNumber === 5 ? '':'none'}}>
            <div className='before'></div>
            <div className='after'></div>
        </div>

            <div id='beer'>
                <div id='beaker'>
                    <div className={'beer-foam' + beerClasses.join(' ')}>
                        <div className='foam-1'></div>
                        <div className='foam-2'></div>
                        <div className='foam-3'></div>
                        <div className='foam-4'></div>
                        <div className='foam-5'></div>
                        <div className='foam-6'></div>
                        <div className='foam-7'></div>
                        <div className='foam-8'></div>
                        <div className='foam-9'></div>
                        <div className='foam-10'></div>
                    </div>
                    <div className={'liquid' + beerClasses.join(' ')}>
                        <div className='bubble bubble1'></div>
                        <div className='bubble bubble2'></div>
                        <div className='bubble bubble3'></div>
                        <div className='bubble bubble4'></div>
                        <div className='bubble bubble5'></div>
                        <div className='bubble bubble6'></div>
                        <div className='bubble bubble7'></div>
                    </div>
                </div>
            </div>
        </div>
    )

}
